<template>
  <div style="width: 50px; height: 100%; position: absolute;" @mouseover="overlayMenuActive = true">
  </div>
  <div
      :style="bgStyle"
      :class="isAuthenticated ? containerClass : null"
      @click="onWrapperClick"
  >
    <!--    <AppTopBar v-if="isAuthenticated" @menu-toggle="onMenuToggle"/>-->
    <transition v-if="isAuthenticated" name="layout-sidebar">
      <div v-show="isSidebarVisible()" :class="sidebarClass" @click="onSidebarClick"
           @mouseleave="overlayMenuActive = false">
        <AppProfile/>
        <AppMenu ref="main-menu" :model="menu" @menuitem-click="onMenuItemClick"/>
        <div class="logo"></div>
      </div>
    </transition>

    <div class="layout-main">
      <Message v-for="msg of messages" :key="msg.content" :closable="true" :life="msg.life" :severity="msg.severity"
               :sticky="false">{{ msg.content }}
      </Message>
      <router-view/>
    </div>

    <AppConfig :layoutColorMode="layoutColorMode" :layoutMode="layoutMode" :isAuthenticated="isAuthenticated" @layout-change="onLayoutChange"
               @layout-color-change="onLayoutColorChange"/>
    <ConfirmDialog></ConfirmDialog>
    <!--    <AppFooter/>-->
  </div>
</template>

<style scoped>
.layout-menu-container {
  height: calc(100% - 220px);
  overflow-y: scroll;
}

.logo {
  display: block;
  position: absolute;
  left: 76px;
  bottom: 24px;
  width: 113px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url(/assets/logo.png);
}

.layout-sidebar-dark .logo {
  background-image: url(/assets/logo-dark.png);
}

.layout-sidebar.layout-sidebar-light {
  overflow-y: hidden;
}
</style>

<style lang="scss">
@import '../node_modules/typeface-roboto/index.css';
@import './App.scss';
</style>
<script>
import AppProfile from './AppProfile.vue';
import AppMenu from './AppMenu.vue';
import AppConfig from './AppConfig.vue';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  data() {
    return {
      isAuthenticated: false,
      layoutMode: 'static',
      layoutColorMode: 'light',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      messages: [],
      errorMessageId: null,
      bgStyle: ''
    }
  },
  mounted() {
    this.fetchLocale();
    this.errorMessageId = setInterval(() => {
      if (localStorage.getItem('storedMessage') && localStorage.getItem('storedMessage').length > 0) {
        this.messages.push(JSON.parse(localStorage.getItem('storedMessage')))
        localStorage.setItem('storedMessage', '')
      }

      if (localStorage.getItem('layoutMode') && localStorage.getItem('layoutMode').length > 0 && localStorage.getItem('layoutMode') !== this.layoutMode) {
        this.layoutMode = localStorage.getItem('layoutMode')
      } else if(!localStorage.getItem('layoutMode') || localStorage.getItem('layoutMode').length === 0) {
        localStorage.setItem('layoutMode', 'static')
      }
    }, 100);
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();

      console.log(this.$route.name)
      this.bgStyle = this.$route.name === 'login' ? 'background-image: url(\'/assets/login_bg.jpg\'); height: 100vh;' : ''
      this.isAuthenticated =
          (!!localStorage.getItem('authtoken') && localStorage.getItem('authtoken') !== '')
          && !(this.$route.name === 'login')
    },
    layoutMode(value) {
      localStorage.setItem('layoutMode', value)
    }
  },
  methods: {
    ...mapActions({
      fetchLocale: 'locale/getLocale'
    }),
    ...mapMutations({
      // TODO something
    }),
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === 'overlay') {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === 'static') {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList)
        element.classList.add(className);
      else
        element.className += ' ' + className;
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className);
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === 'static')
          return !this.staticMenuInactive;
        else if (this.layoutMode === 'overlay')
          return this.overlayMenuActive;
        else
          return true;
      } else {
        return true;
      }
    },
    showMessage(message, severity = 'info', life = 10000) {
      this.messages.push({
        content: message,
        severity: severity,
        life: life
      })
    },
  },
  computed: {
    ...mapGetters({
      'menu': 'getMenu',
    }),
    containerClass() {
      return ['layout-wrapper', {
        'layout-overlay': this.layoutMode === 'overlay',
        'layout-static': this.layoutMode === 'static',
        'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
        'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
        'layout-mobile-sidebar-active': this.mobileMenuActive,
        'p-input-filled': this.$appState.inputStyle === 'filled',
        'p-ripple-disabled': this.$primevue.config.ripple === false
      }];
    },
    sidebarClass() {
      return ['layout-sidebar', {
        'layout-sidebar-dark': this.layoutColorMode === 'dark',
        'layout-sidebar-light': this.layoutColorMode === 'light'
      }];
    },
    logo() {
      return (this.layoutColorMode === 'dark') ? "assets/layout/images/logo-white.svg" : "assets/layout/images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else
      this.removeClass(document.body, 'body-overflow-hidden');
  },
  components: {
    // 'AppTopBar': AppTopBar,
    'AppProfile': AppProfile,
    'AppMenu': AppMenu,
    'AppConfig': AppConfig,
    // 'AppFooter': AppFooter,
  }
}
</script>
